@import "styles/variables.scss";
.build-your-home {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  display: flex;
  overflow: hidden;
  @media (max-width: $tablet) {
    height: 536px;
  }
  @media (min-width: $desktop) {
    height: 752px;
  }
  &__img {
    width: 100%;
    height: 100%;
    &,
    img {
      object-fit: cover;
    }
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &__title {
    @media (max-width: $tablet) {
      max-width: 225px;
      margin-top: 48px;
      margin-left: 16px;
    }
    @media (min-width: $desktop) {
      max-width: 449px;
      letter-spacing: -1px;
      margin-top: 120px;
    }
  }
  &__title,
  &__title p {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -1.1px;
    color: $yellow;
    @media (max-width: $tablet) {
      letter-spacing: -1px;
    }
    @media (min-width: $desktop) {
      font-size: 46px;
      line-height: 64px;
    }
  }
}
