@import "styles/variables.scss";

.build {
    background-color: $yellow;
    display: flex;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: $tablet) {
        padding: 40px 16px 48px;
    }

    @media (min-width: $desktop) {
        padding: 80px 0 70px;
    }

    &__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width: $tablet) {
            display: flex;
            flex-direction: column;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;

        @media (max-width: $tablet) {
            margin-bottom: 48px;
        }
        
        @media (min-width: $desktop) {
            max-width: 350px;
        }

        & h3 {
            font-style: normal;
            font-weight: 700;

            @media (max-width: $tablet) {
                font-size: 30px;
                line-height: 40px;
                letter-spacing: -1.100000023841858px;
                margin-bottom: 16px;
            }

            @media (min-width: $desktop) {
                font-size: 46px;
                line-height: 64px;
                letter-spacing: -1.2000000476837158px;
                margin-bottom: 32px;
            }
        }
    }

    &__right {
        max-width: 648px;

        & ul {
            & li {
                display: flex;
                position: relative;
                justify-content: space-between;

                &:last-child {
                    padding-bottom: 0;
                }

                @media (max-width: $tablet) {
                    padding: 16px 0 24px;
                }
            
                @media (min-width: $desktop) {
                    padding: 16px 0 44px;
                }

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 4px;
                    background: $black;
                }

                & span {
                    font-style: normal;
                    font-weight: 700;
                    border: 2px solid $black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;

                    @media (max-width: $tablet) {
                        min-width: 32px;
                        height: 32px;
                        margin-right: 16px;
                        letter-spacing: -0.4000000059604645px;
                        line-height: 24px;
                        font-size: 18px;
                    }
                
                    @media (min-width: $desktop) {
                        min-width: 40px;
                        height: 40px;
                        margin-right: 24px;
                        letter-spacing: -1.100000023841858px;
                        line-height: 40px;
                        font-size: 30px;
                    }
                }
            }
        }
    }

    &__right-wrap {
        @media (min-width: $desktop) {
            margin-right: 56px;
        }
    }

    &__right-title {
        font-style: normal;
        font-weight: 700;

        @media (max-width: $tablet) {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.4000000059604645px;
            margin-bottom: 16px;
            max-width: 240px;
        }
    
        @media (min-width: $desktop) {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.8999999761581421px;
            margin-bottom: 6px;
        }
    }

    &__right-title--last {
        font-style: normal;
        font-weight: 700;

        @media (max-width: $tablet) {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.4000000059604645px;
        }
    
        @media (min-width: $desktop) {
            font-size: 30px;
            line-height: 32px;
            letter-spacing: -0.8999999761581421px;
            margin-bottom: 6px;
        }
    }

    &__right-text {
        font-style: normal;
        font-weight: 400;

        @media (max-width: $tablet) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.001em;
        }
    
        @media (min-width: $desktop) {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.4000000059604645px;
        }
    }

    &__right-icon {
        @media (max-width: $tablet) {
            min-width: 40px;
            height: 40px;
        }
    
        @media (min-width: $desktop) {
            min-width: 64px;
            height: 64px;
        }
    }
}