@import "styles/variables.scss";

.third-slider {
  position: relative;
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &__img {
    width: 100%;
    &,
    img {
      object-fit: cover;
    }

    @media (max-width: $tablet) {
      height: 536px;
    }

    @media (min-width: $desktop) {
      height: 654px;
    }
  }

  &::after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $blackFont;
    opacity: 0.9;
    z-index: 1;
  }

  &__wrap {
    position: absolute;
    z-index: 2;

    @media (max-width: $tablet) {
      top: 48px;
      left: 40px;
      max-width: fit-content;
    }

    @media (min-width: $desktop) {
      top: 88px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__button {
    margin-top: 24px;
  }

  &__info {
    width: fit-content;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile) {
      max-width: 90%;
    }

    &::before {
      display: block;
      content: "";
      height: 8px;
      background: $white;

      @media (max-width: $tablet) {
        width: 132px;
        margin-bottom: 10px;
      }

      @media (min-width: $desktop) {
        width: 171px;
      }
    }

    p {
      font-weight: 700;
      font-style: normal;
      color: $yellow;
      letter-spacing: -1.2px;

      @media (max-width: $tablet) {
        font-size: 45px;
        margin-bottom: 10px;
        max-width: 375px;
      }

      @media (min-width: $desktop) {
        max-width: 1000px;
        margin-bottom: 25px;
        font-size: 85px;
        line-height: 105px;
      }

      & strong {
        color: $white;
      }
    }
  }
}
