@import "styles/variables.scss";

.easy-block {
  @media (max-width: $tablet) {
    padding: 56px 16px;
  }

  @media (min-width: $desktop) {
    padding: 60px 0 120px;
  }

  &__wrap {
    display: flex;
    flex-direction: row-reverse;
    
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  
    @media (min-width: $desktop) {
      justify-content: space-between;
    }
  }

  & h2 {
    font-style: normal;
    font-weight: 700;

    @media (max-width: $tablet) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.8999999761581421px;
      margin-bottom: 16px;
    }
  
    @media (min-width: $desktop) {
      font-size: 46px;
      line-height: 64px;
      letter-spacing: -1.2000000476837158px;
    }
  }

  &__text {
    max-width: 739px;

    & p {
      font-style: normal;
      
      @media (max-width: $tablet) {
        line-height: 24px;
        letter-spacing: -0.4000000059604645px;
        font-size: 18px;
        font-weight: 400;
      }
    
      @media (min-width: $desktop) {
        font-size: 30px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -1.100000023841858px;
      }   
    }
  }
}