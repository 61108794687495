@import "styles/variables.scss";

.fourth-slider {
  position: relative;
  display: flex;
  max-width: 1920px;
  margin: 0 auto;

  &__img {
    width: 100%;
    &, img {
      object-fit: cover;
    }
    @media (max-width: $tablet) {
      height: 536px;
    }

    @media (min-width: $desktop) {
      height: 654px;
    }
  }

  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    @media (max-width: $tablet) {
      height: 536px;
      padding-left: 20px;
    }

    @media (min-width: $desktop) {
      height: 654px;
    }

    &::before {
      display: block;
      content: '';
      width: 50%;
      height: 654px;
      background: $yellow;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;

      @media (max-width: $tablet) {
        height: 50%;
        width: 100%;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    margin-top: 104px;
  }

  &__title {
    max-width: 496px;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    line-height: 100px;
    letter-spacing: -1.2px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (max-width: $tablet) {
      height: 536px;
      font-size: 30px;
      line-height: 30px;
    }

    &::before {
      display: block;
      content: '';
      width: 200px;
      height: 8px;
      background: $black;
      margin-left: auto;
      margin-bottom: 20px;

      @media (max-width: $tablet) {
        width: 100px;
        margin-left: 0;
      }
    }
  }
}