@import 'styles/variables.scss';

.house-slider {
  position: relative;
  display: flex !important;
  max-width: 1920px;
  min-height: 300px;
  margin: 0 auto;

  &__img {
    width: 100%;
    img {
      object-fit: cover;
    }

    @media (max-width: $tablet) {
      height: 356px;
    }

    @media (min-width: $desktop) {
      height: 654px;
    }
  }

  &__wrap {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__top {
    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      background: $white;
      position: absolute;
      width: 152px;
      height: 48px;
      padding: 16px 24px;
      top: -1px;
      right: 0;
      border-bottom-left-radius: 20px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.4000000059604645px;
      box-sizing: border-box;
      z-index: 2;
    }
  }

  &__bottom {
    position: absolute;
    right: 60px;
    bottom: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: $white;
  }
}
